<template>
  <v-list-item
    link
    @click="$emit('staffClick', staff)"
    :disabled="gettingHours"
  >
    <v-list-item-avatar color="primary">
      <span
        class="white--text text-uppercase"
        style="margin: 0 auto;"
      >{{ initials }}</span>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title> {{ staff.address.name_line }} </v-list-item-title>
      <v-list-item-subtitle> {{ staff.ssn }} </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content
      v-if="gettingHours && hoursWorkedThisMonth === null"
      style="max-width: 200px;"
    >
      <v-progress-linear
        indeterminate
        height="20"
      />
    </v-list-item-content>
    <v-list-item-content
      v-if="hoursWorkedThisMonth !== null"
      style="max-width: 40px;"
    >
      <v-progress-circular
        :rotate="-90"
        :value="hoursWorkedThisMonthPercent"
        :color="hoursWorkedThisMonthColor"
        :indeterminate="!workInStore"
        height="20"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
/**
 * @property {String} total_hours
 */
export default {
	name: 'StaffListItem',
  props: {
    staff: {
      type: Object,
      default: () => {}
    }
  },
	data: () => ({
		startTime: 0,
		endTime: 0,
		hoursWorkedThisMonth: null,
		gettingHours: false,
	}),
	beforeMount() {
		this.gettingHours = true
		let date = new Date()
		if(this.$store.state.organizationUUID === 'e9a99986-427d-456a-a7ab-ee770c8e9086') {
			this.startTime = date.getDate() >= 26 ? new Date(date.getFullYear(), date.getMonth(), 26).getTime() : new Date(date.getFullYear(), date.getMonth() - 1, 26).getTime()
		} else {
			this.startTime =  new Date(date.getFullYear(), date.getMonth(), 1).getTime()
		}
	},
	mounted() {
		this.hoursWorkedThisMonth = this.getWorkHoursThisMonth(this.workInStore, this.staff, this.startTime)
	},
	methods: {
		getWorkHoursThisMonth(work, staff, startTime) {
			let totalHours = 0
			
			if(work !== null && work.length > 0) {
				const length = work.length

				for(let i = 0; i < length; i++) {
					const log = work[i]

					if(log.status === 'cancelled') {
						continue
					}
					
					if(log.staff !== undefined && log.staff !== null &&  log.staff.uuid === staff.uuid && startTime < log.start_date) {
						totalHours += log.total_hours || 0
					}
				}
			}
			this.gettingHours = false
			return totalHours
		}
	},
	watch: {
		hoursWorkedThisMonth(value) {
			if(value) {
				this.gettingHours = false
			}
		},
		workInStore(newWork) {
			if(newWork !== null) {
				this.hoursWorkedThisMonth = this.getWorkHoursThisMonth(newWork, this.staff, this.startTime)
			} else {
				this.gettingHours = false
			}
		}
	},
	computed: {
		workInStore() {
			return this.$store.state.work
		},
		hoursWorkedThisMonthColor() {

			if(this.hoursWorkedThisMonth >= 173) {
				return 'red'
			}
			else if(this.hoursWorkedThisMonth > 40) {
				return 'green'
			}
			else if(this.hoursWorkedThisMonth > 150) {
				return 'orange'
			}
			else {
				return 'blue'
			}

		},
		hoursWorkedThisMonthPercent() {

			if(this.hoursWorkedThisMonth === 0) {
				return 0
			}

			return (this.hoursWorkedThisMonth / 173) * 100
		},
		initials() {
			const name = this.staff?.address?.name_line ?? null

			if(!name) {
				return 'n/a' // name not available
			}

			// We split on white space and underscore
			let names = name.split(/[\s_]/gi)

			// Here we are getting checking how many names are in a name. If there are more than 3 names we use the first name alongside the last name
			if(names.length > 3) {
				names = [ names[0], ...names.slice(-1) ]
			}

			// Creating the initials string
			return names.reduce((initials, namePart) => {
				// Set the max length to show in the avatar, it will start to look bad with more than three letters
				if(initials.length >= 3) {
					return initials
				}

				// Here we could do return (initials + name[0]).toUppercase()) to make sure the return itself is uppercase
				// Currently we are forcing the uppercase within the DOM itself
				return initials + (namePart?.[0] ?? '')
			}, '')
		}
	}
}
</script>
