<template>
  <div>
    <div>
      <ActionList
        :filters="filters"
        :load-items-callback="loadItemsCallback"
        :fixed-filters="fixedFilters"
        :empty-text="$t('noStaffFound')"
        :call-to-actions="callToActions"
      >
        <template v-slot:item="{ item, index }">
          <StaffListItem
            :key="item.uuid"
            :staff="item"
            @staffClick="$emit('staffClick', item)"
          />
          <v-divider
            :key="index"
            inset
          />
        </template>
      </ActionList>
    </div>
  </div>
</template>

<script>
  import ActionList from "./common/list/ActionList"
  import StaffListItem from "../components/StaffListItem"

  export default {
    name: 'StaffList',
    props: {
      loadItemsCallback: {
        type: Function, 
        default: () => null
      },
      callToActions: {
        type: Array,
        default: () => []
      },
      fixedFilters: {
        type: Array, 
        default: () => []
      }
    },
    components: {
      StaffListItem,
      ActionList
    },
    data: () => ({
      startIndex: 0,
      isLoadingMore: false,
    }),
    computed: {
      filters() {
        return []
      },
      staff() {
        return this.$store.state.staff
      }
    }
  }
</script>
